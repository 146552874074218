<template>
    <div>
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
            <div class="card-header">
                <div class="card-title">
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-select
                                :options="originDestinationlist"
                                value-field="id"
                                text-field="name"
                                v-model="form.origin"
                                size="md">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Select Origin</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="col-md-6">
                            <b-form-select
                                :options="originDestinationlist"
                                value-field="id"
                                text-field="name"
                                v-model="form.destination"
                                size="md">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Select Destination</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar">
                    <b-button variant="success" @click="searchRates"><i class="flaticon-search"></i> Search</b-button>
                </div>
            </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Rates</h3>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="row">
                <div class="table-responsive">
                    <div class="col-md-12">
                        <b-table
                        id="search-rate-tbl"
                        :per-page="perPage"
                        :current-page="currentPage"
                        responsive
                        class="text-nowrap"
                        striped
                        hover
                        show-empty
                        :items="rates"
                        :fields="fields"
                        >
                        </b-table>
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        align="right"
                        aria-controls="search-rate-tbl"
                    ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading :active.sync="isLoading"></loading>
    </div>
  </template>
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import ApiService from "@/core/services/api.service";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  export default {
    data() {
      return {
        form: {
            origin:null,
            destination:null,
        },
        isLoading: false,
        originDestinationlist:[],
        rows: 0,
        perPage: 30,
        currentPage: 1,
        rates:[],
        fields: [
          { key: "weight", label: "Weight" },
          { key: "rate_type", label: "Rate Type" },
          {
            key: "rate",
            label: "Rate",
            formatter: (value) => {
              return value + " " + "SGD";
            },
          },
          { key: "shipping_mode", label: "Mode",
            formatter: (value) => {
                if (value == "" || value == null) {
                return "All";
                } else {
                return value;
                }
            },
          },
          { key: "shipment_type", label: "Shipment Type",
            formatter: (value) => {
                if (value == "" || value == null) {
                return "All";
                } else {
                return value;
                }
            },
          },
          { key: "rate_name", label: "Name" },
        ],
      };
    },
    created() {
      this.getRate();
    },
    components: {
      Loading,
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Shipping" },
        { title: "Rate" },
      ]);
      this.getAllZones();
    },
    methods: {
      makeToastVariant(variant = null, text, title) {
        this.$bvToast.toast(text, {
          title: title,
          variant: variant,
          solid: true,
        });
      },
      getAllZones() {
      ApiService.get("shippingzone")
        .then(({ data }) => {
          this.originDestinationlist = data;
        })
        .catch(() => {
        });
      },
      searchRates(){
        this.isLoading = true;
        ApiService.post("searchshippingrate", this.form)
          .then(({ data }) => {
            this.rates = data.rates;
            this.currentPage = 1;
            this.rows = this.rates.length;
            this.isLoading = false;
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
  };
  </script>
  